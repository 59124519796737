import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import unauthApplication from "./views/unauth/unauthenticated-mfe-layout.html";

const routes = constructRoutes(unauthApplication);
const applications = constructApplications({
  routes,
  loadApp: ({ name }) => {
    return System.import(name);
  },
});

const layoutEngine = constructLayoutEngine({
  routes,
  applications,
});

applications.forEach((app) => {
  registerApplication({
    name: app.name,
    app: app.app,
    activeWhen: () => true,
  });
});

layoutEngine.activate();
start();
